import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import loadingGif from './loading.gif';  // Make sure you have this file in the src folder

const App = () => {
  const [formData, setFormData] = useState({
    mood: 'super happy!',
    reason: 'I broke up with my *** boyfriend! New Start!',
    weather: 'heavy rain...',
    time: 'Sunday Evening!',
    drink_type: 'Cocktail',
  });
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:9996/recommend';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(backendUrl, formData);
      setResult(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const resetForm = () => {
    setResult(null);
  };

  return (
    <div className="App">
      <h1>What to Drink Today? 🥂</h1>
      {loading ? (
        <div className="loading-container">
          <img src={loadingGif} alt="Loading..." />
        </div>
      ) : result ? (
        <div className="result">
          <h2 className="drink-suggestion">Today you might want...</h2>
          <h1 className="drink-name">{result.name}!!</h1>
          <div className="section">
            <h3>Ingredients</h3>
            <ul className="ingredients-list">
              {Object.entries(result.ingredients).map(([key, value]) => (
                <li key={key}>{`${key}: ${value}`}</li>
              ))}
            </ul>
          </div>
          <div className="section">
            <h3>Steps</h3>
            <ol>
              {result.steps.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ol>
          </div>
          <div className="section">
            <h3>Something To You...</h3>
            <p>{result.wish}</p>
          </div>
          <div className="result-buttons">
            <button className="button" onClick={handleSubmit}>Another Try?</button>
            <button className="button" onClick={resetForm}>Something Changed?</button>
          </div>
        </div>
      ) : (
        <div className="form">
          <label>
            😊 or 😥
            <input name="mood" value={formData.mood} onChange={handleChange} />
          </label>
          <label>
            What happened though 👂👂
            <input name="reason" value={formData.reason} onChange={handleChange} />
          </label>
          <label>
            Well, how is the weather now 🥂
            <input name="weather" value={formData.weather} onChange={handleChange} />
          </label>
          <label>
            Btw, what's the time now? 🕘
            <input name="time" value={formData.time} onChange={handleChange} />
          </label>
          <label>
            Guess you have preference on type? 🍹
            <select name="drink_type" value={formData.drink_type} onChange={handleChange}>
              <option value="Cocktail">Cocktail</option>
            </select>
          </label>
          <button className="button" onClick={handleSubmit}>Let me see...</button>
        </div>
      )}
    </div>
  );
};

export default App;
